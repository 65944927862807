<template>
  <PageContainer>
    <BaseSearchForm
      @search="onSearch"
      @reset="onReset"
      :loading="loading"
    >
      <el-form-item label="企业:">
        <el-select v-model="companySelected" filterable placeholder="全部企业" clearable>
          <el-option v-for="item in companyList" :key="item.compId" :value="item.compId" :label="item.compName"/>
        </el-select>
      </el-form-item>
      <el-form-item :label="compTypes.title+':'">
        <el-select v-model="searchForm.compType" filterable  clearable>
          <el-option v-for="item in compTypes.options" :key="item.value" :value="item.value" :label="item.label"/>
        </el-select>
      </el-form-item>
      <el-form-item label="姓名:">
        <el-input v-model.trim="searchForm.persName" placeholder="请输入" clearable/>
      </el-form-item>
      <el-form-item label="驾驶证号:">
        <el-input v-model.trim="searchForm.cardID" placeholder="驾驶证号" clearable />
      </el-form-item>
    </BaseSearchForm>

    <BaseTable
      id="personManage"
      @page-change="handlePageChange"
      :tablePage="tablePage"
      :loading="loading"
      :data="tableData">
      <template #buttons>
        <el-button v-auth="'信息管理_人员管理_新增'" size="small" type="primary" icon="el-icon-plus" @click="addVisible=true">新增</el-button>
        <el-upload
          style="display:inline-block"
          action="#"
          class="upload"
          :auto-upload="false" accept=".xlsx,.xls" :on-change="handleExcel" :show-file-list="false">
          <el-button
            class="ml-10 mr-10"
            v-auth="'信息管理_人员管理__导入'"
            type="default"
            icon="el-icon-download"
          >导入
          </el-button></el-upload>
        <el-button v-auth="'信息管理_人员管理_模板下载'" size="small" type="success" icon="el-icon-tickets" @click="downloadTem();">模板下载</el-button>
      </template>
      <vxe-column field="seq" type="seq" title="序号" width="80" align="center"></vxe-column>
      <vxe-column field="persName" title="员工姓名" min-width="120"></vxe-column>
      <vxe-column field="gend" title="性别" min-width="80"></vxe-column>
      <vxe-column field="politics" title="政治面貌" min-width="100"></vxe-column>
      <vxe-column field="dutyStatName" title="任职状态" min-width="100"></vxe-column>
      <vxe-column field="persTypeName" title="职位/工种" min-width="100"></vxe-column>
      <vxe-column field="lastCompName" title="现工作单位" min-width="220"></vxe-column>
      <vxe-column field="cardID" title="身份证号" min-width="200"></vxe-column>
      <vxe-column field="phone" title="联系方式" min-width="120"></vxe-column>
      <vxe-column field="sociSecuName" title="是否交社保" min-width="120"></vxe-column>
      <vxe-column field="operations" title="操作" :width="385" fixed="right">
        <template #default={row}>
          <el-button v-auth="'信息管理_人员管理_离职'" type="primary" @click="handlerLeave(row)" title="离职">离职</el-button>
          <el-button v-auth="'信息管理_人员管理_转正'" type="primary" @click="handlerFull(row)" title="离职">转正</el-button>
          <el-button v-auth="'信息管理_人员管理_奖惩'" type="primary" @click="handlerBonus(row)" title="奖惩">奖惩</el-button>
          <el-button v-auth="'信息管理_人员管理_修改'" type="primary" @click="handlerEdit(row)" title="修改">修改</el-button>
          <el-button v-auth="'信息管理_人员管理_删除'" type="danger" @click="handlerDelete(row)" title="删除">删除</el-button>
          <el-button type="primary" @click="handlerDetail(row)" title="明细">明细</el-button>
          <el-button type="primary" @click="handlerQrcode(row)" title="二维码">二维码</el-button>
        </template>
      </vxe-column>
    </BaseTable>

    <!-- 新增人员 -->
    <add :visible.sync="addVisible" :edit-data.sync="editData" @refresh="initList" />
    <!-- 离职 -->
    <leave-comp :visible.sync="leaveVisible" :edit-data.sync="editData" @refresh="initList" />
    <!-- 详情 -->
    <detail :type="1" :visible.sync="detailVisible" :edit-data.sync="editData" />
    <!-- 奖惩 -->
    <bonus-penalty :visible.sync="bonusVisible" :edit-data.sync="editData" @refresh="initList" />
    <!-- 转正 -->
    <become-full :visible.sync="fullVisible" :edit-data.sync="editData" @refresh="initList" />
    <qr-code :visible.sync="codeVisible" :content="qrCodeContent" />
    <el-dialog
      title="温馨提示"
      :visible.sync="dialogImport"
      :v-if="dialogImport"
      :append-to-body="true"
      width="750px"
      v-dialogDrag
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <div>
        <div>{{importRes.msg}}</div>
        成功导入<span  style="color: green">{{importRes.successco}}</span>条数据,失败<span style="color: red">{{importRes.failco}}</span>条数据。
        <div
          v-for="(item,index) in importRes.errorList"
          :key="index"
        >
          <span v-for="(value,key) in item" :key="key">{{key}}:{{value}},</span>
        </div>
      </div>
    </el-dialog>
  </PageContainer>
</template>

<script>
import { getPersList, delPers, importExcelPers, downTemPers } from '@/apis/infoManage/common'
import { gianComplist } from '@/apis/common'
import Add from './components/Add'
import LeaveComp from './components/LeaveComp'
import Detail from './components/Detail'
import BonusPenalty from './components/BonusPenalty'
import BecomeFull from './components/BecomeFull'
import QrCode from '@/components/QrCode'
import { dutyTypes, persTypes, impPosts, ccieTypes } from './constants'
import { compTypes } from '@/constants/infoManage/compBaseInfo'
import { exportToxlsx } from '@/utils/tools'
export default {
  data () {
    return {
      compTypes,
      dutyTypes,
      persTypes,
      searchForm: {
        persName: '',
        compType: '', // 企业类型
        cardID: '',
        lastCompId: ''
      },
      // 列表相关
      loading: false,
      tableData: [],
      tablePage: {
        pageNum: 1,
        pageSize: 20,
        totalSize: 0
      },
      // 新增|编辑相关
      editData: null,
      addVisible: false,
      leaveVisible: false,
      detailVisible: false,
      bonusVisible: false,
      fullVisible: false,
      codeVisible: false,
      qrCodeContent: '',
      companyList: [],
      dialogImport: false,
      importRes: {}
    }
  },
  computed: {
    // 选中的企业
    companySelected: {
      set (newVal) {
        const companyList = this.companyList
        const searchForm = this.searchForm
        const { compId } = companyList.find(item => newVal == item.compId) || { compId: '' }
        searchForm.lastCompId = compId
      },
      get () { return this.searchForm.lastCompId }
    }
  },
  created () {
    this.initList()
    this.initCompany()
  },
  methods: {
    downloadTem () {
      downTemPers().then(res => {
        exportToxlsx(res, '模板.xlsx')
      })
    },
    handleExcel (file) {
      this.$confirm('导入数据：姓名，手机号，工种不能为空，所属企业不能为空且名称必须是平台中的企业名称, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const formData = new FormData()
        formData.append('file', file.raw)
        importExcelPers(formData).then(res => {
          if (res.code == '200') {
            if (res.data.errorList.length < 1) {
              this.$message.success('文件导入成功！')
              this.tablePage.pageNum = 1
              this.initList()
            } else {
              this.dialogImport = true
            }
          } else {
            this.$message.error(res.msg)
          }
        })
      })
    },
    onReset () {
      const source = this.$options.data(this).searchForm
      this.searchForm = Object.assign({}, this.searchForm, source)
      this.onSearch()
    },

    async initCompany () {
      const res = await gianComplist({ pageNum: 1, pageSize: 100000 })
      if (res.code == 200) {
        this.companyList = res.data.rows
      } else {
        this.$message.error(res.msg)
      }
    },
    onSearch () {
      this.tablePage.pageNum = 1
      this.initList()
    },

    initList () {
      this.loading = true
      const { totalSize, ...pageParams } = this.tablePage
      const params = Object.assign({}, this.searchForm, pageParams)
      getPersList(params).then((res) => {
        this.loading = false
        if (res.code == 200) {
          this.tableData = res.data.rows.map((item) => {
            const target = dutyTypes.find(({ id }) => id == item.dutyStat) || { name: '' }
            const seniPositName = (impPosts.find(({ id }) => id == item.seniPosit) || { name: '' }).name
            const postName = seniPositName || item.ordiPosit || '未知'
            item.persTypeName = postName + '/' + (persTypes.find(({ id }) => id == item.persType) || { name: '' }).name
            item.dutyStatName = target.name
            item.sociSecuName = item.sociSecu ? '是' : (item.sociSecu === null ? '' : '否')
            // 证书类型
            item.ccieTypeName = (ccieTypes.find(({ id }) => id == item.ccieType) || { name: '' }).name
            return item
          })
          this.tablePage.totalSize = res.data.totalSize
        } else {
          this.$message.error(res.msg)
        }
      })
    },

    handlePageChange ({ currentPage, pageSize }) {
      this.tablePage.pageNum = currentPage
      this.tablePage.pageSize = pageSize
      this.initList()
    },

    handlerEdit (row) {
      this.editData = JSON.parse(JSON.stringify(row))
      this.addVisible = true
    },

    // 离职
    handlerLeave (row) {
      this.editData = JSON.parse(JSON.stringify(row))
      this.leaveVisible = true
    },

    // 明细
    handlerDetail (row) {
      this.editData = JSON.parse(JSON.stringify(row))
      this.detailVisible = true
    },

    // 奖惩
    handlerBonus (row) {
      this.editData = JSON.parse(JSON.stringify(row))
      this.bonusVisible = true
    },

    // 奖惩
    handlerFull (row) {
      this.editData = JSON.parse(JSON.stringify(row))
      this.fullVisible = true
    },

    // 删除
    handlerDelete (row) {
      this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const params = { cardID: row.cardID, persId: row.persId }
        delPers(params).then((res) => {
          if (res.code == 200) {
            this.initList()
            this.$message.success('刪除成功！')
          } else {
            this.$message.error(res.msg)
          }
        })
      })
    },
    changePersType () {
      this.$confirm('确定要给该人员转正吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        // persStatChange()
        // dutyDatTim
        // dutyStat
        // dutyReason
        // const params = { cardID: row.cardID, persId: row.persId }
        // delPers(params).then((res) => {
        //   if(res.code == 200) {
        //     this.initList()
        //     this.$message.success('刪除成功！')
        //   } else {
        //     this.$message.error(res.msg)
        //   }
        // })
      })
    },
    handlerQrcode (row) {
      this.codeVisible = true
      this.qrCodeContent = `姓名: ${row.persName};身份证号: ${row.cardID};`
    }
  },
  components: {
    Add,
    LeaveComp,
    Detail,
    BonusPenalty,
    BecomeFull,
    QrCode
  }
}
</script>

<style lang="scss" scoped>
  .system-icon-box {
    .system-icon-item {
      object-fit: contain;
      vertical-align: middle;
    }
  }
</style>
